import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "./assets/css/style.css";


import AdminDashboard from "./components/admin/AdminDashboard";
import AdminLogin from "./components/admin/AdminLogin";
import AdminLayout from "./components/admin/AdminLayout";
import DashboardOne from "./components/admin/DashboardOne";
import UserStatisticsDashboard from "./components/admin/UserStatistics/UserStatisticsDashboard";
import FundDashboard from "./components/admin/Fund/FundDashboard";
import MembersList from "./components/admin/UserStatistics/MembersList";
import BalanceStatisticsDashboard from "./components/admin/BalanceStatistics/BalanceStatisticsDashboard";
import TotalTransferDashboard from "./components/admin/TotalTransfers/TotalTransferDashboard";
import TotalPackagesDashboard from "./components/admin/TotalPackages/TotalPackagesDashboard";
import MonthlyFundsDashboard from "./components/admin/MonthlyFund/MonthlyFundsDashboard";
import PackagesStatisticsDashboard from "./components/admin/PackageStatistics/PackagesStatisticsDashboard";
import BonusStatisticsDashboard from "./components/admin/BonusStatistics/BonusStatisticsDashboard";
import CompanyProfitDashboard from "./components/admin/CompanyProfit/CompanyProfitDashboard";
import DepositDashboard from "./components/admin/Deposit/DepositDashboard";
import WithdrawalDashboard from "./components/admin/Withdrawal/WithdrawalDashboard";
import BonusPanelDashboard from "./components/admin/BonusPanel/BonusPanelDashboard";
// import RewardBonusDashboard from " ./components/admin/RewardBonus/RewardBonusDashboard";
// import OtherRewardBonusDashboard from " ./components/admin/OtherRewardBonus/OtherRewardBonusDashboard";
import CommodityPannelDashboard from "./components/admin/CommodityPanel/CommodityPannelDashboard";
import RoiDashboard from "./components/admin/Roi/RoiDashboard";
import TreeBonusDashboard from "./components/admin/TreeBonus/TreeBonusDashboard";
// import MartixBonusDashboard from "./components/admin/MatrixBonus/MartixBonusDashboard";
import AdminCommodityDashboard from "./components/admin/Commodity/AdminCommodityDashboard";
import KycRequest from "./components/admin/UserStatistics/KycRequests";
import UserDetailsPage from "./components/admin/UserStatistics/UserDetailsPage";


import TotalPackageList from "./components/admin/Fund/TotalPackageList";
import CompanyProfitList from "./components/admin/CompanyProfit/CompanyProfitList";
import CryptoDeposit from "./components/admin/Deposit/CryptoDeposit";
import NowPayment from "./components/admin/Deposit/NowPayment";
import CoinPayment from "./components/admin/Deposit/CoinPayment";
import TotalDeposit from "./components/admin/Deposit/TotalDeposit";
import CryptoWithdrawalList from "./components/admin/transactions/Withdrawal/CryptoWithdrawalList";
import DirectBonusList from "./components/admin/BonusPanel/DirectBonusList";
import NetworkBonusList from "./components/admin/BonusPanel/NetworkBonusList"
import ReferralBonusList from "./components/admin/BonusPanel/ReferralBonusList";
import RenewalBonusList from "./components/admin/BonusPanel/RenewalBonusList";
import AdminInvestorBonus from "./components/admin/CommodityPanel/AdminInvestorBonus";
import AdminRepurchaseBonus from "./components/admin/CommodityPanel/AdminRepurchaseBonus"
import AdminRpInvestorBonus from "./components/admin/CommodityPanel/AdminRpInvestorBonus";
import OpenTicketList from "./components/admin/Support/OpenTicketList";
import Approved from "./components/admin/Withdrawal/Approved";
import Rejected from "./components/admin/Withdrawal/Rejected";
import Succeeded from "./components/admin/Withdrawal/Succeeded";
import Canceled from "./components/admin/Withdrawal/Canceled";
import Initiated from "./components/admin/Withdrawal/Initiated";
import AdminDirectBonus from "./components/admin/CommodityPanel/AdminDirectBonus";
import SecurityDashboard from "./components/admin/Security/SecurityDashboard";
import ManageUsers from "./components/admin/Security/ManageUsers";
import AddUser from "./components/admin/Security/AddUser";
import UpdateUser from "./components/admin/Security/UpdateUser";
import ManageGroup from "./components/admin/Security/ManageGroup";
import AddGroup from "./components/admin/Security/AddGroup";
import UpdateGroup from "./components/admin/Security/UpdateGroup";
import RightAssignment from "./components/admin/Security/RightAssignment";
import NoAccess from "./components/admin/NoAccess";
import AdminTicketsDetail from "./components/admin/Support/AdminTicketsDetail";
import CloseTicketList from "./components/admin/Support/CloseTicketList";
import PendingTicketList from "./components/admin/Support/PendingTicketList";


import RejectedDeposit from "./components/admin/Deposit/RejectedDeposit";
import PendingDeposit from "./components/admin/Deposit/PendingDeposit";

import SupportDashboard from "./components/admin/Support/SupportDashboard";
import NewTicketList from "./components/admin/Support/NewTicketList";

import DirectRank from "./components/admin/Ranks/DirectRank";
import Reward from "./components/admin/Reward/Reward";
import TradingList from "./components/admin/PackageStatistics/TradingList";
import SettingsDashboard from "./components/admin/Settings/SettingsDashboard";
import Transactions from "./components/admin/Transaction/Transactions";
import TransDeposit from "./components/admin/Transaction/TransDeposit";
import TransTrading from "./components/admin/Transaction/TransTrading";
import TransFirst from "./components/admin/Transaction/TransFirst";
import TransSecond from "./components/admin/Transaction/TransSecond";
import TransThird from "./components/admin/Transaction/TransThird";
import TransSelfRoi from "./components/admin/Transaction/TransSelfRoi";
import TransMyBox from "./components/admin/Transaction/TransMyBox";
import TransReward from "./components/admin/Transaction/TransReward";

import TransTransfers from "./components/admin/Transaction/TransTransfers"

import AdminNetwork from "./components/admin/AdminNetwork/AdminNetwork";
import AdminReferral from "./components/admin/AdminReferrals/AdminReferral";
import TransTransfersReceived from "./components/admin/Transaction/TransTransfersReceived";
import MysteryListing from "./components/admin/Reward/MysteryListing";
import AdminTransfers from "./components/admin/TotalTransfers/AdminTransfers";
import MutualTransfers from "./components/admin/TotalTransfers/MutualTransfers";
import UpdateProfilebyAdmin from "./components/admin/UserStatistics/UpdateProfilebyAdmin";
import KYCApproved from "./components/admin/UserStatistics/KYCApproved";
import PurchaseNow from "./components/admin/Purchase/PurchaseNow";
import AddPackage from "./components/admin/Packages/AddPackage";
import ManagePackage from "./components/admin/Packages/ManagePackage";
import UpdatePackage from "./components/admin/Packages/UpdatePackage";
import AdminProfileUpdate from "./components/admin/AdminProfileUpdate";
import AdminFeeDashboard from "./components/admin/TowergadFee/AdminFeeDashboard";
import Coin from "./components/admin/Coin/Coin";
import AutoCollectFee from "./components/admin/TotalTransfers/AutoCollectFee";



function Index() {

  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AdminLogin />} />
        <Route path="/login" element={<AdminLogin />} />
        <Route path="/admin/login" element={<AdminLogin />} />

        <Route path="/admin" element={<AdminLayout />}>
          <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/dashboard1" element={<DashboardOne />} />
          <Route path="/admin/balance-statistics/dashboard" element={<BalanceStatisticsDashboard />} />
          <Route path="/admin/Admin-fee/dashboard" element={<AdminFeeDashboard />} />
          <Route path="/admin/total-packages/dashboard" element={<TotalPackagesDashboard />} />
          <Route path="/admin/monthly-funds/dashboard" element={<MonthlyFundsDashboard />} />
          <Route path="/admin/packages-statistics/dashboard" element={<PackagesStatisticsDashboard />} />
          <Route path="/admin/trading-list" element={<TradingList />} />
          <Route path="/admin/bonus-statistics/dashboard" element={<BonusStatisticsDashboard />} />
          <Route path="/admin/support/detail/:id" element={<AdminTicketsDetail />} />


          <Route path="/admin/Admin-Update-Profile" element={<AdminProfileUpdate />} />
          <Route path="/admin/total-transfer/autocollectfee" element={<AutoCollectFee />} />



          {/* settings */}
          <Route path="/admin/setting/dashboard" element={<SettingsDashboard />} />


          {/* add package  */}

          <Route path="/admin/package/add-package" element={<AddPackage />} />
          <Route path="/admin/package/manage-package" element={<ManagePackage />} />
          <Route path="/admin/package/update-package/:id" element={<UpdatePackage />} />

          {/* user statisics  */}

          <Route path="/admin/userstatistics/dashboard" element={<UserStatisticsDashboard />} />
          <Route path="/admin/userstatistics/userstatus/:userstatus" element={<MembersList />} />
          <Route path="/admin/userstatistics/Total-user" element={<MembersList />} />
          <Route path="/admin/userstatistics/KycRequests" element={<KycRequest />} />
          <Route path="/admin/userstatistics/KYCApproved" element={<KYCApproved />} />
          <Route path="/admin/userstatistics/user-details/:KYCID" element={<UserDetailsPage />} />
          <Route path="/admin/userstatistics/update-profile/:id" element={<UpdateProfilebyAdmin />} />

          {/* start dashboard 1 side bar  */}
          {/* company profit  */}

          <Route path="/admin/company-profit/dashboard" element={<CompanyProfitDashboard />} />
          <Route path="/admin/company-profit/total-profit" element={<CompanyProfitList />} />

          {/* deposit panel  */}

          <Route path="/admin/deposit/dashboard" element={<DepositDashboard />} />
          <Route path="/admin/deposit/total-deposit" element={<TotalDeposit />} />
          <Route path="/admin/deposit/crypto-deposit" element={<CryptoDeposit />} />
          <Route path="/admin/deposit/coin-payment" element={<CoinPayment />} />
          <Route path="/admin/deposit/now-payment" element={<NowPayment />} />
          <Route path="/admin/deposit/rejected-deposit" element={<RejectedDeposit />} />
          <Route path="/admin/deposit/pending-deposit" element={<PendingDeposit />} />


          {/* transfers */}
          <Route path="/admin/total-transfer/dashboard" element={<TotalTransferDashboard />} />
          <Route path="/admin/total-transfer/admin-transfers" element={<AdminTransfers />} />
          <Route path="/admin/total-transfer/mutual-transfers" element={<MutualTransfers />} />



          {/* coin */}

          <Route path="/admin/coin" element={< Coin />} />

          {/* withdrawal panel  */}

          <Route path="/admin/withdrawal/dashboard" element={< WithdrawalDashboard />} />
          <Route path="/admin/withdrawal/initiated" element={<Initiated />} />
          <Route path="/admin/withdrawal/approved" element={<Approved />} />
          <Route path="/admin/withdrawal/rejected" element={<Rejected />} />
          <Route path="/admin/withdrawal/success" element={<Succeeded />} />
          <Route path="/admin/withdrawal/canceled" element={<Canceled />} />


          {/* bonus panel  */}

          <Route path="/admin/bonus-panel/dashboard" element={<BonusPanelDashboard />} />
          <Route path="/admin/bonus-panel/direct-bonus-list" element={<DirectBonusList />} />
          <Route path="/admin/bonus-panel/network-bonus-list" element={<NetworkBonusList />} />
          <Route path="/admin/bonus-panel/referral-bonus-list" element={<ReferralBonusList />} />
          <Route path="/admin/bonus-panel/renewal-bonus-list" element={<RenewalBonusList />} />


          {/* Purchase Now  */}

          <Route path="/admin/PurchaseNow" element={<PurchaseNow />} />



          {/* Direct Rank  */}

          <Route path="/admin/ranks/direct-rank" element={<DirectRank />} />
          {/* Reward  */}

          <Route path="/admin/Reward" element={<Reward />} />

          {/* commodity panel  */}

          <Route path="/admin/commodity-panel/dashboard" element={<CommodityPannelDashboard />} />
          <Route path="/admin/commodity-panel/direct-bonus" element={<AdminDirectBonus />} />
          <Route path="/admin/commodity-panel/investor-bonus" element={<AdminInvestorBonus />} />
          <Route path="/admin/commodity-panel/repurchase-bonus" element={<AdminRepurchaseBonus />} />
          <Route path="/admin/commodity-panel/rp-investor-bonus" element={<AdminRpInvestorBonus />} />

          {/* start dashboard 3 side bar  */}
          <Route path="/admin/roi/dashboard" element={<RoiDashboard />} />
          <Route path="/admin/treebonus/dashboard" element={<TreeBonusDashboard />} />
          <Route path="/admin/commodity/dashboard" element={<AdminCommodityDashboard />} />
          <Route path="/admin/fund/dashboard" element={<FundDashboard />} />
          <Route path="/admin/fund/total-packages" element={<TotalPackageList />} />

          {/* ADMIN TRANSACTIONS DASHBOARD */}
          <Route path="/admin/transactions/dashboard" element={<Transactions />} />
          <Route path="/admin/transactions/trading/:id" element={<TransTrading />} />
          <Route path="/admin/transactions/first-level/:id" element={<TransFirst />} />
          <Route path="/admin/transactions/second-level/:id" element={<TransSecond />} />
          <Route path="/admin/transactions/third-level/:id" element={<TransThird />} />
          <Route path="/admin/transactions/self-roi/:id" element={<TransSelfRoi />} />
          <Route path="/admin/transactions/mystery/:id" element={<TransMyBox />} />
          <Route path="/admin/transactions/rewards/:id" element={<TransReward />} />
          <Route path="/admin/transactions/transfer/sent/:id" element={<TransTransfers />} />
          <Route path="/admin/transactions/transfer/received/:id" element={<TransTransfersReceived />} />
          <Route path="/admin/transactions/deposit/:id" element={<TransDeposit />} />







          <Route path="/admin/transaction/crypto-withdrawal-list" element={<CryptoWithdrawalList />} />
          <Route path="/admin/mystery-box-deductions" element={<MysteryListing />} />

          {/* Network */}
          <Route path="/admin/member-network/:id" element={<AdminNetwork />} />
          <Route path="/admin/Admin-Referrals/:id" element={<AdminReferral />} />


          {/* ADMIN SUPPORTPANEL */}

          <Route path="/admin/support/dashboard" element={<SupportDashboard />} />
          <Route path="/admin/support/open-ticket" element={<OpenTicketList />} />
          <Route path="/admin/support/new-ticket" element={<NewTicketList />} />

          {/* ADMIN SECURITY PANEL */}

          <Route path="/admin/security/dashboard" element={<SecurityDashboard />} />
          <Route path="/admin/security/manage-users" element={<ManageUsers />} />
          <Route path="/admin/security/manage-users/add" element={<AddUser />} />
          <Route path="/admin/security/manage-users/edit/:id" element={<UpdateUser />} />
          <Route path="/admin/security/manage-groups" element={<ManageGroup />} />
          <Route path="/admin/security/manage-groups/add" element={<AddGroup />} />
          <Route path="/admin/security/manage-groups/edit/:id" element={<UpdateGroup />} />
          <Route path="/admin/security/manage-groups/right-assignment/:id" element={<RightAssignment />} />




          <Route path="/admin/no-access" element={<NoAccess />} />



          <Route path="/admin/support/close-ticket" element={<CloseTicketList />} />
          <Route path="/admin/support/pending-ticket" element={<PendingTicketList />} />






        </Route>
      </Routes>
    </BrowserRouter>

  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
