import React, { useState, useEffect } from 'react'
import Spinner from "react-bootstrap/Spinner";
import axios from "axios"
import config from '../../../Config';
import dayjs from "dayjs";
import { NavLink, useParams } from 'react-router-dom';
import Pagination from "../../member/General/Pagination";
import { calculateColumnTotal } from '../../Common/Util';
import { NumberFormat, NumberFormatDecimal } from '../../member/General/Functions';
import AdminRightsCheck from '../AdminRightsCheck';
import { Descriptions } from 'antd';
const Coin = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [loading, setLoading] = useState(false);
    const [listOfTransfers, setlistOfTransfers] = useState([]);
    const [totalTransactionAmount, settotalTransactionAmount] = useState(0);
    const [totalReceiveableAmount, settotalReceiveableAmount] = useState(0);
    const [totalFee, settotalFee] = useState(0);

    const [showFilters, setShowFilters] = useState(true);
    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [UserName, setUserName] = useState('');
    const [filterSalePurchase, setFilterSalePurchase] = useState("");
    const [filterEarned, setFilterEarned] = useState("");
    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    let params = useParams();

    useEffect(() => {
        document.title = "Coin";

        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            startDate: StartDate,
            endDate: EndDate,
            UserName: UserName,
            Description : filterEarned,
            INOut: filterSalePurchase

        };




        var api_config = {
            method: 'post',
            url: config.base_url + 'Users/GetAllCoins',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                setlistOfTransfers(response.data.ListofLedger);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });


    }, [pageNo, pageSize, StartDate, EndDate, UserName,filterSalePurchase,filterEarned]);


    const renderLedger = () => {
        if (listOfTransfers != null && listOfTransfers.length > 0) {
            return listOfTransfers.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{item.ID}</td>
                        <td>{dayjs(item.Dated).format("DD/MM/YYYY")}</td>
                        <td>{item.User_Name}</td>
                        <td>{item.Email_Address}</td>
                        <td>{item.Description}</td>
                        <td>{NumberFormatDecimal(item.InQuantity, 8)}</td>
                        <td>{NumberFormatDecimal(item.OutQuantity, 8)}</td>
                        <td>{NumberFormatDecimal(item.BalanceQuantity, 8)}</td>
                        <td>{NumberFormatDecimal(item.BuyingSellingRate, 8)}</td>
                        <td>{NumberFormatDecimal(item.PurchaseAmount, 8)}</td>
                        <td>{NumberFormatDecimal(item.TotallPurchaseAmount, 8)}</td>
                        <td>{NumberFormatDecimal(item.NextRate, 8)}</td>
                        <td>{item.IDPercentage}</td>
                        <td>{NumberFormatDecimal(item.IDPercentageAmount, 8)}</td>
                        <td>{NumberFormatDecimal(item.IDValue, 8)}</td>

                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="15">
                        <h4 className="mt-3" style={{ fontSize: "15px", color: "white" }}>
                            No Record Exists
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };
    return (
        <>
            <AdminRightsCheck />
            <div className="admin-content">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="d-title m-0">BIC Coin</div>
                    </div>
                    <div className="col-md-6 text-end">
                        <div className="">
                            <NavLink to="/admin/total-transfer/dashboard">
                                <button type="button" className="button button-primary">
                                    Back
                                </button>
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary ms-3"
                                style={{ display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="material-symbols-outlined ms-2">
                                    page_info
                                </i>
                            </button>
                        </div>
                    </div>
                </div>
                {/* Filters Menu */}
                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row p-3 align-items-end gy-3">
                                    <div className="col-md-2">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={StartDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={EndDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">Email Address</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={UserName}
                                                        onChange={(e) => setUserName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="">In/Out</label>
                                            <div className="inputwrap mt-2 mb-2">
                                                <select className="form-select" value={filterSalePurchase}
                                                    onChange={(e) => setFilterSalePurchase(e.target.value)}>
                                                    <option value="">All</option>
                                                    <option value="Sale">Sale</option>
                                                    <option value="Purchase">Purchase</option>
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Earned/Purchase</label>
                                            <div className="inputwrap mt-2 mb-2">
                                                <select className="form-select" value={filterEarned}
                                                    onChange={(e) => setFilterEarned(e.target.value)}>
                                                    <option value="">All</option>
                                                    <option value="Coin Earned">Coin Earned</option>
                                                    <option value="Coin Purchased">Coin Purchased</option>
                                                </select>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <TransactionsMenu /> */}
                <div className="card overflow-auto card-table mb-5 mt-3">
                    <div className="card-inner">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Date</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Description</th>
                                    <th>In</th>
                                    <th>Out</th>
                                    <th>Balance Quantity</th>
                                    <th>Rate</th>
                                    <th>Amount</th>
                                    <th>Purchase Amount</th>
                                    <th>Next Rate</th>
                                    <th>Percentage</th>
                                    <th>Amount</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="15">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {renderLedger()}
                                        {/* <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='text-right'>Page Total:</td>
                                            <td>${calculateColumnTotal(listOfTransfers, 'TotallPurchaseAmount')}</td>
                                            <td>${calculateColumnTotal(listOfTransfers, 'PurchaseAmount')}</td>
                                            <td>${calculateColumnTotal(listOfTransfers, 'NextRate')}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='text-right'> Grand Total:</td>
                                            <td>${totalTransactionAmount}</td>
                                            <td>${totalReceiveableAmount}</td>
                                            <td>${totalFee}</td>
                                        </tr> */}

                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default Coin