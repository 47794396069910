import React, { useState } from "react";
import AdminHeader from "./AdminHeader";
import { NavLink, Outlet } from "react-router-dom";
import AdminLoginCheck from "./AdminLoginCheck";
import AdminRightsCheck from "./AdminRightsCheck";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo/logo.gif";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.js";

function AdminLayout(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(isMenuOpen ? false : true);
  };

  const closeMenu = () => {
    var admin_side_bar = document.getElementById("admin_side_bar");
    var overlay = document.getElementById("overlaymenu");
    var menubutton = document.getElementById("menubutton");
    var body = document.getElementById("body");
    body.classList.remove("overflow-hidden");
    if (admin_side_bar) {
      admin_side_bar.classList.remove("active");
    }

    if (overlay) {
      overlay.classList.remove("active");
    }

    ////console.log(menubutton);

    if (menubutton) {
      menubutton.classList.remove("active");
    }
  };

  let navigate = useNavigate();
  const handleSignOut = () => {
    localStorage.clear();
    navigate("/admin/login");
  };

  return (
    <>
      <AdminLoginCheck />
      <AdminRightsCheck />
      <div id="admin-body">

        <div onClick={closeMenu} id="overlaymenu"></div>
        <div className="admin-wrap">
          <div className={`admin-left`} id="admin_side_bar">
            <ul className="admin_side_nav">
              <li>
                <NavLink className="navbar-brand" to="/admin/dashboard">
                  <img className="img-fluid" src={Logo} />
                </NavLink>

              </li>
              <li>
                <NavLink to="/admin/dashboard">
                  {/* <i className="material-symbols-outlined">dashboard</i> */}
                  <i className="material-symbols-outlined">grid_view</i>
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/userstatistics/dashboard">
                  <i className="material-symbols-outlined">trending_up</i>
                  <span>User Statistics</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/package/manage-package">
                  <i className="material-symbols-outlined">package</i>
                  <span>Manage Packages</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/coin">
                  <i className="material-symbols-outlined">paid</i>
                  <span>Coins</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/total-transfer/dashboard">
                  <i className="material-symbols-outlined">local_atm</i>
                  <span>Total Transfers</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/admin/Admin-fee/dashboard">
                  <i className="material-symbols-outlined"> credit_score</i>
                  <span>Admin Fee</span>
                </NavLink>
              </li>

               <li>
                <NavLink to="/admin/total-packages/dashboard">
                  <i className="material-symbols-outlined">package</i>
                  <span>Total Trading</span>
                </NavLink>
              </li> */}

              <li>
                <NavLink to="/admin/trading-list">
                  <i className="material-symbols-outlined">equalizer</i>
                  <span>Purchasings</span>
                </NavLink>
              </li>

              {/* start dashboard 1 side bar  */}

              <li>
                <NavLink to="/admin/deposit/dashboard">
                  <i className="material-symbols-outlined">insert_chart</i>
                  <span>Deposit Statistics</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/withdrawal/dashboard">
                  <i className="material-symbols-outlined">
                    account_balance_wallet
                  </i>
                  <span>Withdrawal</span>
                </NavLink>
              </li>

              {/* start dashboard 3 from here  */}

              <li>
                <NavLink to="/admin/roi/dashboard">
                  <i className="material-symbols-outlined">timeline</i>
                  <span>Send ROI</span>
                </NavLink>
              </li>

              {/* <li>
                <NavLink to="/admin/Reward">
                  <i className="material-symbols-outlined">
                    emoji_events
                  </i>
                  <span>Rewards</span>
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink to="/admin/ranks/direct-rank">
                  <i className="material-symbols-outlined">
                    stars
                  </i>
                  <span>Direct Ranks</span>
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink to="/admin/mystery-box-deductions">
                  <i className="material-symbols-outlined">equalizer</i>
                  <span>MystryBox Deductions</span>
                </NavLink>
              </li> */}
              {/* 
              <li>
                <NavLink to="/admin/PurchaseNow">
                  <i className="material-symbols-outlined">business_center</i>
                  <span>Purchase for Now</span>
                </NavLink>
              </li> */}

              <li>
                <NavLink to="/admin/security/dashboard">
                  <i className="material-symbols-outlined">privacy_tip</i>
                  <span>Security</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/setting/dashboard">
                  <i className="material-symbols-outlined">settings</i>
                  <span>Settings</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/Admin-Update-Profile">
                  <i class="material-symbols-outlined">
                    key
                  </i>
                  <span>Change Password</span>
                </NavLink>
              </li>
              <li>
                <a href="#" onClick={handleSignOut}>
                  <i className="material-symbols-outlined">logout</i>
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="admin-right" id="right-side">
            <AdminHeader />
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLayout;
