export const NumberFormat = (value) => {
  if (value == null) {
    return "";
  }
  else {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);

    return formattedNumber;
  }

};
export const NumberFormatDecimal = (value, point) => {
  if (value == null || point == null) {
    return "";
  }
  else {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: point,
      maximumFractionDigits: point,
    }).format(value);

    return formattedNumber;
  }

};

export const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};
